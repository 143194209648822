var isOnclick = true; //设置点击一次登录按钮后接口没有返回数据就不能再次点击
var redirectURLArray = ["fundBuyMiddle.html"];
var isOpen = false;
var tokenSlider = '';
var deviceId = 'shebeihaoshipc';
var isCheckedVcode = true;// 是否勾选验证码登录协议
// var isCheckedLogin = true;// 是否勾选验证码登录协议
var timeflag = false;//短信验证码是否重发 ture 是 false 否
var timedown = 120;
var timer = null;
var isVcodeLoginFlag = true;// 设置登录密码点击按钮防重
var isChange = false;//是否点击重新发送

$(function () {


    //如果客户浏览器版本是ie6或者ie7，则页面需要提示客户，提示文案为：“您的浏览器版本太低，无法支持鹏华基金高密级安全证书要求，建议使用以下浏览器访问。”
    if (browserFun() === "IE6" || browserFun() === "IE7") {
        // if(true){
        //显示遮罩层  删除登录信息-防止访问其他页面依然能够访问

        $('#browserLow').css("display", "block");
        $('#browserLow').siblings().hide();
        localStorage.clear();
        return false;
    }
    behavioralStatistics('login');
    localStorage.g_mobile = null;
    localStorage.g_cuatna = null;
    localStorage.g_photoPath = null;
    localStorage.MustActiveOlder = "N";
    var sessionId = timeStampFun() + 'abc';
    localStorage.sessionId = sessionId;
    $("#redirectURL").val(localStorage.redirectURL);
    $('#yzmcode').attr('src', baseGetURLNew + 'S092007?date=' + timeStampFun() + '&sessionId=' + sessionId);

    //键盘事件enter监听，出发登录事件
    document.onkeydown = function (event) {
        var e = event || window.event || arguments.callee.caller.arguments[0];
        if (e && e.keyCode == 13) {
            login();
        }
    }

    $("#pwd").blur(function () {
        $("#capslk_open").hide();
        $("#capslk_close").hide();
        isOpen = false;
    });

    getOnlineCustomerServiceLink();
});


// 输入手机号码点击下一步
function next() {
    var u = $.trim($('#phone').val());
    $('#phone').val(u)
    if (u == null || u.length == 0) {
        $('#e_phone').removeClass('none');
        return;
    } else if (u.length <= 11) {
        if (!checkMoblie2("phone", "请输入正确的手机号或身份证号")) {
            return;
        }
    } else {
        if (!IdentityCodeValid("phone", "请输入正确手机号或身份证号")) {
            return;
        }
    }
    if (isCheckedVcode) {
        alert('您需要同意协议');
        return;
    }
    sliderUrl();
}
// 调用滑块验证码
function sliderUrl() {
    toApi_toAcCg("S092023", {},
        function (data) {
            $('#img_sldler').attr('src', data.backgroundImage);
            $('#block-slider').attr('src', data.sliderImage);
            $('#slider_box').css('display', 'block');
            $('#yzm').val('');
            $('#pasd1').val('');
            $('#pasd2').val('');
            $('#vcode').addClass('none');
            $('#vcode1').removeClass('none');
            tokenSlider = data.token;

        }, "login", "",
        function (data) {
            console.log(data);
        });
}
// 验证滑块验证码
function sliderVcode(dragScale) {

    toApi_toAcCg("S15Q083", {
        ratio: String(dragScale),
        token: tokenSlider,
        deviceId: deviceId,
        loginName: $.trim($('#phone').val()),
        codeType: '11',
        messageType: 'SMS'
    },
        function (data) {
            $('#slider_box').css('display', 'none');
            setTimeout(function () {
                block.style.left = '16px';
                moveBlock.style.left = '16px';
                p2.style.width = '16px';
            }, 1000)
            isFlage = false;
            if (data.state == '1') {
                alert(data.respMsg);
            } else {
                // 是老客户，展示不同的文案
                if (data.isOldCustFlag == '1') {
                    $('#login_tips').text('请先设置登录密码，后续可直接使用手机号+登录密码登录');
                } else {
                    $('#login_tips').text('您还不是我们的用户，请先设置登录密码完成注册');
                }
                $('#login2').text('注册');
                var login_Phone = $.trim($('#phone').val())
                if (login_Phone.length > 11) {
                    if (data.bindCardFlag === '0') {
                        alert('您的身份证号未绑卡认证通过，请先使用手机号登录');
                        return;
                    } else {
                        if (data.setLoginPwdFlag === '0') {
                            alert('请你用手机号码登录');
                            return;
                        } else {
                            $('#password_box').css('display', 'block');
                            $('#iphone_box').css('display', 'none');
                            $('#oldNumber').css('display', 'none');
                        }
                    }
                } else {
                    if (data.setLoginPwdFlag === '1') {
                        $('#password_box').css('display', 'block');
                        $('#oldNumber').css('display', 'none');
                        $('#iphone_box').css('display', 'none');
                    } else {
                        // getVcodeHandle(dragScale);
                        getCode();
                        $('#vcode_box').css('display', 'block');
                        $('#iphone_box').css('display', 'none');
                        $('#login1').css('display', 'none');
                        $('#login2').css('display', 'block');
                    }
                }
            }

        }, "login", "",

        function (data) {
            isFlage = false;
        });
}
// 发送验证码接口
function getVcodeHandle(dragScale) {
    const data = {};
    data.teleno = $.trim($('#phone').val());
    data.codeType = '11';
    data.messageType = 'SMS';
    data.ratio = String(dragScale);
    data.token = tokenSlider;
    data.deviceId = deviceId;
    toApi_toAcCg("S092024", data,
        function (data) {
            $('#slider_box').css('display', 'none');
            setTimeout(function () {
                block.style.left = '16px';
                moveBlock.style.left = '16px';
                p2.style.width = '16px';
            }, 1000)
            isChange = false;
            isFlage = false;
            
            if (data.state == '1') {
                alert(data.respMsg);
                $('#vcode').removeClass('none');
                $('#vcode1').addClass('none');
            }else{
                getCode();
                if (timeflag) {
                    timedown = 120;
                    $('#vcode').addClass('none');
                    $('#vcode1').removeClass('none');
                }
            }


        }, "login", "",

        function (data) {

        });
}
function getCode() {
    timer = setInterval(function () {
        timedown--;
        var str = '重发(' + timedown + 's)'
        $('#vcode1').text(str);
        if (timedown === 0) {
            clearInterval(timer);
            $('#vcode1').addClass('none');
            $('#vcode').removeClass('none');
        }
    }, 1000);
}

// 关闭滑块验证码
function closeHandle() {
    $('#slider_box').css('display', 'none');
}
// 刷新滑块验证码
function refrehSlider() {
    block.style.left = '16px';
    moveBlock.style.left = '16px';
    sliderUrl();
}
/**
 * 获取token
 * @returns {null|*}
 */
function getToken() {
    return localStorage.g_token;
}
function isCapsLock(event) {
    var browserMatch = uaMatch(userAgent.toLowerCase());
    if (browserMatch.browser) {
        browser = browserMatch.browser;
        version = browserMatch.version;
    }
    var versionFloat = parseFloat(version);
    if (browser == 'IE' && versionFloat > 10) {
        return;
    }

    var e = event || window.event;
    var keyCode = e.keyCode || e.which; // 按键的keyCode
    var isShift = e.shiftKey || (keyCode == 16) || false; // shift键是否按住
    if (
        ((keyCode >= 65 && keyCode <= 90) && !isShift) || ((keyCode >= 97 && keyCode <= 122) && isShift)) {
        if (!isOpen) {
            $("#capslk_open").show();
            $("#capslk_close").hide();
            isOpen = true;
        }
    } else {
        if (isOpen) {
            $("#capslk_open").hide();
            $("#capslk_close").show();
            isOpen = false;
        }
    }
}

function switchId() {

    var u = $.trim($('#phone').val());
    $('#phone').val(u);
    if (u == null || u.length == 0) {
        $('#e_phone').removeClass('none');
        return;
    } else if (u.length <= 11) {
        if (!checkMoblie2("phone", "请输入正确的手机号或身份证号")) {
            return;
        }
    } else {
        if (!IdentityCodeValid("phone", "手机号或身份证号码格式错误")) {
            return;
        } else {
            $("#phone").val(u.toUpperCase());
            $('#e_phone').addClass('none');
        }
    }

}
// 密码登录
function login() {
    var p = $('#pwd').val();
    var u = $.trim($('#phone').val());
    if (checkRequired2('pwd', '登录密码不能为空')) {
    } else {
        return;
    }

    if (p.length < 8 || p.length > 20) {
        errShow('pwd', '登录密码为8-20位字母或数字组成')
        return;
    }

    // if (isCheckedLogin) {
    //     alert('您需要同意协议');
    //     return;
    // }

    if (isOnclick) {
        isOnclick = false;
        var r_p = RSAUtil(p);
        var sessionId = localStorage.sessionId;
        if (isNull(sessionId)) {
            sessionId = timeStampFun() + 'abc';
        }
        var S092021Parms = {
            loginCode: r_p,
            account: RSAUtil_older(u),
            loginType: 'pwd',
            deviceId: deviceId,
            deviceType: '1'
        };
        toApi_toAcCg("S092026", S092021Parms,
            function (data) {
                localStorage.clear();
                sessionStorage.removeItem('isCustCompletePoP');
                isOnclick = true;
                if (data.respCode === '000000' || data.respCode === '0000') {
                    localStorage.g_token = data.token;
                    localStorage.g_cuatna = data.nickname == null ? '' : data.nickname;
                    localStorage.g_lastTime = data.lastLoginTime;
                    localStorage.g_imagePhotoPath = data.headimgurl;
                    localStorage.setItem("lastLoginTime", data.lastLoginTime);
                    localStorage.isLoginApp = data.isLoginApp; //是否登录过app
                    localStorage.sessionId = data.sessionId; //存储sessioId
                    toApi_toAcCg('S111145', {}, function (data) {
                        PCgetCustState(data);
                    });
                } else if (data.respCode == 'E111030' || data.respCode == 'E111054' || data.respCode == 'E111055' || data.respCode == 'E111056') {
                    showErrorMsg(data.respMsg, '2');
                } else {
                    alertIknowMsg(data.respMsg);
                }

            }, "login", "",

            function (data) {

                isOnclick = true;
            });
    }

}
// 立即登录
function nextLogin() {
    $('#inner_box').addClass('none');
    $('#inner').removeClass('none');
    $('#password_box').css('display', 'block');
    $('#iphone_box').addClass('none');
    $('#oldNumber').addClass('none');
    $('#vcode_box').css('display', 'none');
}
// 登录页面返回上一页
function backPage() {
    $('#password_box').css('display', 'none');
    $('#iphone_box').css('display', 'block');
    $('#oldNumber').css('display', 'block');
    $('#pwd').val('');

}
// 验证码页面返回上一页
function backPagePhone() {
    $('#vcode_box').css('display', 'none');
    $('#iphone_box').css('display', 'block');
    $('#login1').css('display', 'block');
    $('#login2').css('display', 'none');
    timedown = 120;
    clearInterval(timer);
}
// 设置登录密码
function vcodeLogin() {
    var y = $('#yzm').val();
    var pasd1 = $('#pasd1').val();
    var pasd2 = $('#pasd2').val();
    if (checkRequired2('yzm', '验证码不能为空')) {
    } else {
        return;
    }
    if (y == null || y.length == 0) {
        $('#e_yzm').removeClass('none');
        return;
    }
    if (pasd1 == null || pasd1.length == 0) {
        $('#e_yzm1').removeClass('none');
        return;
    }
    if (pasd1.length < 8 || pasd1.length > 20) {
        $('#e_yzm1').removeClass('none');
        $('#e_yzm1').html('<i class="ic-error"></i>' + '登录密码为8-20位字母或数字组成');
        return;
    }
    if (pasd1 != "" && checkStrongPsd(pasd1) < 2) {
        $('#e_yzm1').removeClass('none');
        $('#e_yzm1').html('<i class="ic-error"></i>' + '密码需8-20位，需同时含数字和英文字母');
        return;
    }
    if (pasd2 == null || pasd2.length == 0) {
        $('#e_yzm2').removeClass('none');
        $('#e_yzm2').html('<i class="ic-error"></i>' + '请再次输入登录密码入');
        return;
    }

    if (pasd1 != pasd2) {
        $('#e_yzm2').removeClass('none');
        $('#e_yzm2').html('<i class="ic-error"></i>' + '两次输入密码不一致，请重新输入');
        return;
    }
    // if (isCheckedVcode) {
    //     alert('您需要同意协议');
    //     return;
    // }
    // if (!isVcodeLoginFlag) {
    //     return;
    // }
    isVcodeLoginFlag = false;
    var u = $.trim($('#phone').val());
    var S092057Parms = {
        "deviceId": deviceId,
        "phone": RSAUtil_older(u),
        "vcode": y,
        "codeType": "11",
        "pwd": RSAUtil(pasd1),
        "verifyPwd": RSAUtil(pasd2)
    }
    toApi_toAcCg("S092057", S092057Parms,
        function (data) {
            setTimeout(function () {
                isVcodeLoginFlag = true;
            }, 1000);
            if (data.respCode == '0000' || data.respCode == '000000') {
                $('#vcode_box').css('display', 'none');
                backPagePhone();
            } else {
                alert(data.respMsg);
            }

        }, "login", "",

        function (data) {
            setTimeout(function () {
                isVcodeLoginFlag = true;
            }, 1000);
            alert(data.respMsg)
        });
}
// 勾选协议
function checkedHandle() {
    if (isCheckedVcode) {
        $('#checked1').attr('src', 'images/checked_red.png');
        isCheckedVcode = false;
    } else {
        isCheckedVcode = true;
        $('#checked1').attr('src', 'images/checked.png');
    }
}

/*
    返回密码的强度级别
    */
function checkStrongPsd(sPW) {
    if (sPW.length < 8 || sPW.length > 20)
        return 0; //密码太短或太长
    Modes = 0;
    for (i = 0; i < sPW.length; i++) {
        //测试每一个字符的类别并统计一共有多少种模式.
        Modes |= CharMode(sPW.charCodeAt(i));
    }
    return bitTotal(Modes);
}
/*
 判断字符类型
 */
function CharMode(iN) {
    if (iN >= 48 && iN <= 57) //数字
        return 1;
    if (iN >= 65 && iN <= 90) //大写字母
        return 2;
    if (iN >= 97 && iN <= 122) //小写
        return 4;
    else
        return 8; //特殊字符
}
/*
 统计字符类型
 */
function bitTotal(num) {

    modes = 0;
    for (i = 0; i < 4; i++) {
        if (num & 1) modes++;
        num >>>= 1;
    }
    return modes;
}
function checkedHandle1() {
    if (isCheckedLogin) {
        $('#checked').attr('src', 'images/checked_red.png');
        isCheckedLogin = false;
    } else {
        isCheckedLogin = true;
        $('#checked').attr('src', 'images/checked.png');
    }
}
function inputHadle() {
    var y = $('#yzm').val();
    if (y.length > 0) {
        $('#e_yzm').addClass('none');
        return;
    }
}

function inputHadle1() {
    var y = $('#pasd1').val();
    if (y.length > 0) {
        $('#e_yzm1').addClass('none');
        return;
    }
}
function change() {
    dragScale = 0;
     x = 0;
     y = 0;
    isFlage = true;
    timeflag = true;
    isChange = true;
    sliderUrl();
}
// function login() {
//     var p = $('#pwd').val();
//     var y = $('#yzm').val();
//     if (checkRequired2('pwd', '登录密码不能为空') && checkRequired2('yzm', '图片验证码不能为空')) {

//     } else {
//         return;
//     }

//     if (p.length < 8 || p.length > 20) {
//         errShow('pwd', '登录密码为8-20位字母或数字组成')
//         return;
//     }

//     if (y == null || y.length == 0) {
//         $('#e_yzm').removeClass('none');
//         return;
//     }

//     if (isOnclick) {
//         isOnclick = false;

//         var r_p = RSAUtil(p);

//         var sessionId = localStorage.sessionId;
//         if (isNull(sessionId)) {
//             sessionId = timeStampFun() + 'abc';
//         }
//         var imgCode = $('#yzm').val();
//         var signUrl = "loginName=" + u + "&loginPwd=" + r_p + "&imgCode=" + y + "&deviceType=1&deviceId=shebeihaoshipc";
//         var signKey = signUrl + "&key=abc123";
//         var sign = hex_md5(signKey);
//         var S092021Parms = {
//             "loginName": u,
//             "loginPwd": r_p,
//             "imgCode": y,
//             "deviceType": '1',
//             "deviceId": 'shebeihaoshipc',
//             "sign": sign,
//         };
//         toApi_toAc("S092021", S092021Parms,
//             //toApi_toLogin({url: 'login.tml?' + signUrl + '&sign=' + sign},
//             function (data) {
//                 localStorage.clear();
//                 sessionStorage.removeItem('isCustCompletePoP');
//                 isOnclick = true;
//                 if (data.respCode === '000000' || data.respCode === '0000') {

//                     localStorage.g_token = data.token;
//                     localStorage.g_cuatna = data.cuatna == null ? '' : data.cuatna;
//                     localStorage.g_lastTime = data.lastLoginTime;
//                     localStorage.g_imagePhotoPath = data.imagePhotoPath;
//                     localStorage.setItem("lastLoginTime", data.lastLoginTime);
//                     localStorage.isLoginApp = data.isLoginApp; //是否登录过app
//                     localStorage.sessionId = data.sessionId; //存储sessioId
//                     toApi_toAc('S111145', {}, function (data) {
//                         console.log(data)
//                         PCgetCustState(data);
//                     });
//                     // S111103
//                     //} else {
//                     //    localStorage.g_mobile = u;
//                     //    saveValue('regPhone', u, true);
//                     //老客户激活补充代码：zhangxl
//                     //    isOlderUser();
//                     //}
//                 } else if (data.respCode == 'E111030' || data.respCode == 'E111054' || data.respCode == 'E111055' || data.respCode == 'E111056') {
//                     showErrorMsg(data.respMsg, '2');
//                     // change();
//                 } else {
//                     // alert(1)
//                     // change();
//                     alertIknowMsg(data.respMsg);
//                 }

//             }, "login", "",

//             function (data) {
//                 if (data.respCode === "0810010030") {
//                     var alhtml = '<div id="popalert" class="pup-card-bind pup-model pup-hAuto">'
//                         + '<div class="pup-box">'
//                         + '<div class="tips-font error"> <span>您还未注册，请先完成注册</span></div>'
//                         + '<div class="btn-box pt_10">'
//                         + '<p><a href="register.html" class="btn-blue" >立即注册</a></p>'
//                         + '<a  href="javascript:closeAlert()" class="btn-text">暂不</a> </div>'
//                         + '</div>'
//                         + '</div>';
//                     $('body').append(alhtml);
//                 } else if (data.respCode === '0848000135') {
//                     var blhtml = '<div id="popalert" class="pup-card-bind pup-model pup-hAuto">'
//                         + '<div class="pup-box">'
//                         + '<div class="tips-font error"> <span>您的账号还未激活，请先完成激活</span></div>'
//                         + '<div class="btn-box pt_10">'
//                         + '<p><a href="oldSysLogin.html" class="btn-blue" >立即激活</a></p>'
//                         + '<a  href="javascript:closeAlert()" class="btn-text">暂不</a> </div>'
//                         + '</div>'
//                         + '</div>';
//                     $('body').append(blhtml);
//                 } else if (data.respCode == 'E111030' || data.respCode == 'E111054' || data.respCode == 'E111055' || data.respCode == 'E111056') {
//                     showErrorMsg(data.respMsg, '2');
//                 } else {
//                     showErrorMsg(data.respMsg, data.respType);
//                 }
//                 if (data.respCode === "0810010026") {
//                     $("#pwd").val("");
//                     $("#yzm").val("");
//                     // change();
//                 } else {
//                     $("#yzm").val("");
//                     // change();
//                 }
//                 isOnclick = true;
//             });
//     }

// }



/**
 * 查询客户是否绑卡和完善资料
 */
saveValue('realNameParams_stage', '0', false);
saveValue('finishPersonalInfoParams_stage', '0', false);
function PCgetCustState(userinfoData) {
    toApi_toAc('S111027', {}, function (data) {
        //transAcctStatus 状态为 1 时说明可以交易，未实名时，这个值是空的
        saveValue('reigsterNew_transAcctStatus', data.transAcctStatus, false);
        if ('1' == data.transAcctStatus) {
            //可交易
        } else {
            //实名标志  1 实名 0 未实名
            if ('1' == data.certFlag) {
                //未实名 密码设置标志 1 已设置 0 未设置
                if ('1' == data.passwdFlag) {
                    //实名查询资料
                    toApi_toAc('S111102', {}, function (data) {
                        //资料完善的阶段
                        saveValue('storeType', data.storeType, false);
                        saveValue('finishPersonalInfoParams_stage', '1', false);
                        saveValue('realNameParams_stage', '0', false);
                    });
                } else {
                    saveValue('setPwdInfo', data.openTransOrder, false);
                    saveValue('finishPersonalInfoParams_stage', '0', false);
                    saveValue('realNameParams_stage', '2', false);
                }
            } else {
                saveValue('finishPersonalInfoParams_stage', '0', false);
                saveValue('realNameParams_stage', '1', false);
            }
        }


        PCconstinueUserInfoFun(userinfoData);

    });
}

function PCconstinueUserInfoFun(data) {
    localStorage.g_mobile = data.cuinfo.teleNo;
    var activeFlag = data.cuinfo.activateFlag;
    var activeType = data.cuinfo.custSpecies;
    localStorage.g_idcard = data.cuinfo.idtfno;//
    localStorage.g_activeType = data.cuinfo.custSpecies;
    localStorage.isauth = data.cuinfo.isauth;//
    localStorage.risklv = data.cuinfo.risklv;//
    localStorage.riskLevelDesc = data.cuinfo.riskLevelDesc;//
    localStorage.expireDate = data.cuinfo.expireDate;
    localStorage.isOpenWbAcct = data.cuinfo.isOpenWbAcct;
    localStorage.upRealNameIdCard = data.upRealNameIdCard;//
    localStorage.upTransIdCard = data.upTransIdCard;//
    localStorage.ctidFail = data.ctidFail;

    initControlState(data);
    saveValue('regPhone', data.cuinfo.teleNo, true);
    if (activeFlag == "0" && activeType == "B") {
        //if(1==2){
        //未激活且是B类客户
        localStorage.MustActiveOlder = "Y";
        window.location = "older-active.html";
    } else {
        var redirectURL = $("#redirectURL").val();
        if (!isNull(redirectURL) && redirectURLArray.Exists(redirectURL)) {
            //需要重定向的url处理
            window.location = redirectURL;

        } else

            /*jilu函数用于设置一个参数，到个人页面的时候用与判断登陆一次记录一次*/
            sessionStorage.jiluFlag = "yes";
        //console.log("denglu---"+  sessionStorage .jiluFlag);
        window.location = "index.html";
    }
}

// function change() {
//     var sessionId = timeStampFun() + 'abc';
//     localStorage.sessionId = sessionId;
//     console.log(baseGetURLNew,timeStampFun(),sessionId)
//     $('#yzmcode').attr('src', baseGetURLNew + 'S092007?date=' + timeStampFun() + '&sessionId=' + sessionId);
// }


function isHide() {
    var input2 = document.getElementById("pwd");
    if (input2.type == 'password') {
        input2.setAttribute('type', 'text'); //显示明文
        $("#eye").removeClass('switch-off');
        $('#eye').addClass('login-ic');
        $("#eye").addClass('switch');
    } else {
        input2.setAttribute('type', 'password'); //显示小圆点
        $("#eye").removeClass('switch');
        $('#eye').addClass('login-ic');
        $("#eye").addClass('switch-off');
    }
}



function browserFun() {
    if (isIe()) {
        // return isIe6()?"IE6":(  isIe7()?"IE7":(     isIe8()?"IE8":"IE9+"    )    )
        return isIe6() ? "IE6" : (isIe7() ? "IE7" : "IE8+")
    } else {
        //非IE暂不处理
        return "非IE";
    }
}

//判断是否为IE：
function isIe() {
    return ("ActiveXObject" in window);
}
//IE6：
function isIe6() {
    // ie6是不支持window.XMLHttpRequest的
    return isIe() && !window.XMLHttpRequest;
}
//IE7：
function isIe7() {
    //只有IE8+才支持document.documentMode
    return isIe() && window.XMLHttpRequest && !document.documentMode;
}
//IE8：
function isIe8() {
    // alert(!-[1,])//->IE678返回NaN 所以!NaN为true 标准浏览器返回-1 所以!-1为false
    return isIe() && !-[1,] && document.documentMode;
}


var userAgent = navigator.userAgent,
    rMsie = /(msie\s|trident.*rv:)([\w.]+)/,
    rFirefox = /(firefox)\/([\w.]+)/,
    rOpera = /(opera).+version\/([\w.]+)/,
    rChrome = /(chrome)\/([\w.]+)/,
    rSafari = /version\/([\w.]+).*(safari)/;
var browser;
var version;
function uaMatch(ua) {
    var match = rMsie.exec(ua);
    if (match != null) {
        return { browser: "IE", version: match[2] || "0" };
    }
    var match = rFirefox.exec(ua);
    if (match != null) {
        return { browser: match[1] || "", version: match[2] || "0" };
    }
    var match = rOpera.exec(ua);
    if (match != null) {
        return { browser: match[1] || "", version: match[2] || "0" };
    }
    var match = rChrome.exec(ua);
    if (match != null) {
        return { browser: match[1] || "", version: match[2] || "0" };
    }
    var match = rSafari.exec(ua);
    if (match != null) {
        return { browser: match[2] || "", version: match[1] || "0" };
    }
    if (match != null) {
        return { browser: "", version: "0" };
    }
}

function saveValue(key, value) {
    localStorage.setItem(key, value);
}

function loadValue(key) {
    return localStorage.getItem(key);
}

function riskLevelMismatchHide() {

    $("#riskLevelMismatch").hide();
}